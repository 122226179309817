.ck-body-wrapper .ck.ck-balloon-panel{
    z-index: 10000;
}

.retailerProfileWysiwyg {
    .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
        border-radius: 0 0 8px 8px;
    }
    .ck-toolbar.ck-toolbar_grouping {
        border-radius: 8px 8px 0 0 !important;
    }
    .ck.ck-toolbar.ck-toolbar_grouping {
        background-color: #F4F4F6;
    }
}
